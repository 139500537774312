<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <ion-button (click)="dismiss()" *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Import shortcodes -->
      <ion-button *ngIf="mode !== 'pick'" (click)="store()" fill="clear" color="dark">
        <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
        <ion-label [hidden]="!view.isDesktop" [innerHTML]="'import'|translate"></ion-label>
      </ion-button>

      <!-- Add shortcode -->
      <ion-button (click)="createShortcode($event)" shape="round" color="primary" fill="solid" *ngIf="mode !== 'pick'">
        <ion-icon slot="start" name="add"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Type Segment bar -->
  <ion-toolbar class="ion-no-border second-toolbar container" *ngIf="!!view.promptTypes">
    <ion-segment scrollable [(ngModel)]="view.promptType" (ionChange)="onPromptTypeChanged()">
      <ion-segment-button *ngFor="let promptType of view.promptTypes; trackBy:trackItems" [value]="promptType.uid">
        <ion-icon *ngIf="!!promptType.icon" [name]="promptType.icon"></ion-icon>
        <ion-label [innerHTML]="promptType.name|translate"></ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar [(items)]="view.commandsList" [options]="view.selectionOptions" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Refresh list -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <!-- Intro card -->
    <div class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <!-- Loading entries -->
    <ion-spinner [hidden]="!view.loading"></ion-spinner>

    <!-- No entries -->
    <pipeline-no-entries-card
      *ngIf="!!view.commandsList && !view.commandsList.length && !view.loading"></pipeline-no-entries-card>

    <!-- Commands list -->
    <div *ngIf="!!view.commandsList && !!view.commandsList.length">

      <ion-card *ngFor="let tool of view.commandsList; trackBy:trackItems"
        [disabled]="(!post || !post.post_content) && (tool.type !== 'custom')"
        [hidden]="(!!tool.promptType && tool.promptType !== view.promptType) || (tool.type !== 'custom' && view.promptType !== 'user')">

        <ion-item>

          <ion-radio slot="start" *ngIf="mode === 'pick' && !multiple" (click)="choose(tool)"></ion-radio>

          <ion-checkbox *ngIf="!(mode === 'pick' && !multiple)" [(ngModel)]="tool.checked" justify="start"
            labelPlacement="end" (ionChange)="onItemChecked(tool)" slot="start"></ion-checkbox>

          <ion-icon [name]="tool.icon || 'extension-puzzle-outline'" slot="start" (click)="choose(tool)"></ion-icon>

          <ion-label class="ion-text-wrap" (click)="choose(tool)">

            <h2 *ngIf="tool.type === 'custom'" [innerHTML]="tool.name"></h2>
            <h2 *ngIf="tool.type !== 'custom'" [innerHTML]="('ai_tools_' + tool.key)|translate"></h2>

          </ion-label>

          <ion-button *ngIf="tool.type === 'custom'" slot="end" color="dark" fill="clear" icon-only
            (click)="edit(tool)">
            <ion-icon name="settings-outline"></ion-icon>
          </ion-button>

          <ion-button *ngIf="tool.type === 'custom'" slot="end" fill="clear" color="dark" icon-only
            (click)="duplicate(tool)">
            <ion-icon name="copy-outline" slot="start"></ion-icon>
          </ion-button>

          <ion-button *ngIf="tool.type === 'custom'" slot="end" color="danger" fill="clear" icon-only
            (click)="deleteShortcode(tool)">
            <ion-icon name="trash-outline"></ion-icon>
          </ion-button>

          <ion-note slot="end" *ngIf="!!tool.token">
            <span [innerHTML]="tool.token"></span>&nbsp;<span [innerHTML]="'token'|translate"></span>
          </ion-note>

        </ion-item>

        <ion-item lines="none">
          <ion-label class="ion-text-wrap">
            <p *ngIf="!tool.input" [innerHTML]="('ai_tools_' + tool.key + '_description')|translate"></p>
            <p *ngIf="tool.input" [innerHTML]="tool.input"></p>
          </ion-label>
        </ion-item>

      </ion-card>

    </div>

  </div>

</ion-content>