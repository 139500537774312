import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { BlogAdminService } from 'src/app/services/pipeline/blog-admin.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { CreatorsService } from 'src/app/services/pipeline/creators.service';
import { FoldersService } from 'src/app/services/utils/folders.service';
import { PostsService } from 'src/app/services/posts/posts.service';
import { UserService } from 'src/app/services/core/user.service';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PostsAdminService {

  _detailItem: post | null;

  DRAFTS_POSTS_KEY: string = 'post_drafts';

  key: string = 'postsAdmin_posts';

  constructor(
    private AppCMS: AppcmsService,
    private blogAdmin: BlogAdminService,
    private chooser: ChooserService,
    private creators: CreatorsService,
    private folders: FoldersService,
    private posts: PostsService,
    private userService: UserService,
  ) {
  }

  accept(post: post) {
    post.category = post.category || '';
    return this.blogAdmin.executeAccept(post);
  }

  async add_media_from_library(options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', options, ['posts', 'addMediaFromLibrary']);
  }

  appendExternalPostsBySourceUid(blogSourceUid: number, posts: post[], blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.posts.getPostsBySourceUid(blogSourceUid, blForceRefresh)
        .then((sourcePosts: post[]) => {

          sourcePosts.forEach((post: post) => {
            post.type = 'external';
          });

          resolve(
            (posts || []).concat((sourcePosts || []))
          );
        })
        .catch(reject);
    });
  }

  async delete(postId: number) {
    return this.AppCMS.loadPluginData('pipeline', {
      post: {
        uid: postId,
      },
    }, ['posts', 'deleteDraft']);
  }

  async chooseFolder(options: any = {}) {

    const chooseConfig: chooseConfig = Object.assign(options || {}, {
      data: await this.getFolders({}, true),
      labelKey: "title",
      multiple: false,
      service: this,
      valueKey: "uid",
    });

    return this.chooser.choose(chooseConfig);
  }

  createFolder(folder: folder) {
    folder.location = folder.location || 'posts';
    return this.folders.create(folder);
  }

  deleteFolder(folderId: number) {
    return this.folders.delete(folderId);
  }

  detailItem(item: post | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  getAll(options: any = {}, blForceRefresh: boolean = false,) {
    return new Promise(async (resolve, reject) => {
      options.blogSourceUid = options.blogSourceUid || this.userService.getClassification('blogSourceUid');

      this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
        uid: this.userService.getUid(),
      }), ['posts', 'user'], {}, blForceRefresh)
        .then(async (response: any) => {
          let posts: post[] = [];

          if (!!response && !!response.hasOwnProperty('posts')) {
            posts = (response.posts || []);
          } else
            if (!!response && !!response[0] && !!response[0].uid) {
              posts = (response || []);
            }

          if (options.blogSourceUid) {
            this.appendExternalPostsBySourceUid(
              options.blogSourceUid, posts
            )
              .then((posts: post[]) => {

                if (posts && posts.length) {
                  posts.forEach((post: post) => {
                    post = this.posts.getFullPost(post);
                    post.timestamp_formatted = moment(post.date_gmt || post.timestamp).format('DD.MM.YYYY HH:mm');
                    post.type = post.type || 'draft';
                  });
                }

                response.posts = posts;
                resolve(response);
              })
              .catch((error: any) => {
                console.warn('> appending blog posts by blog uid failed', error);
                resolve(response);
              });
          } else {

            if (posts && posts.length) {
              posts.forEach((post: post) => {
                post = this.posts.getFullPost(post);
                post.timestamp_formatted = moment(post.date_gmt || post.timestamp).format('DD.MM.YYYY HH:mm');
                post.type = post.type || 'draft';
              });
            }

            response.posts = posts;
            resolve(response);
          }

        })
        .catch(reject);
    });
  }

  getByFolder(
    folderId: number,
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.folders.getFolderItems(folderId, blForceRefresh, params);
  }

  getFolders(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    options.filter = options.filter || {};
    options.filter.location = options.filter.location || 'posts';
    return this.folders.get(options.filter, blForceRefresh, options);
  }

  async getSettings(blForceRefresh: boolean = false, options: any = {}) {
    return this.creators.getSettings('posts', blForceRefresh, options);
  }

  getStatisticsByPostUid(postId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {}, ['posts', postId, 'statistics'], {}, blForceRefresh);
  }

  moveFolder(posts: any, options: any = {}) {

    if (!!posts && (!!posts.ID || !!posts.uid)) {
      posts = [posts];
    }

    return this.folders.moveFolder(posts as any, 'posts');
  }

  async optimize(options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', options, ['posts', 'optimize']);
  }

  async recreate(options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', options, ['posts', 'recreate']);
  }

  search(query: string, options: any = {}, blForceRefresh: boolean = false) {
    options = options || {};
    options.search = options.search || {};
    options.search.query = query;

    return this.AppCMS.loadPluginData('pipeline', options, ['posts', 'search'], {}, blForceRefresh);
  }

  setCurrentBlogSource(url: string) {
    console.log('> setCurrentBlogSource', url);
  }

  async submitForReview(post: post) {
    post.type = 'review';

    return this.AppCMS.loadPluginData('pipeline', {
      post: post,
    }, ['posts', 'submitForReview']);
  }

  async submitPost(_post: post) {
    return new Promise((resolve, reject) => {
      let post: any = JSON.parse(JSON.stringify(_post)),
        _moment = moment(new Date());

      post.timestamp = _moment.format("YYYY-MM-DD HH:mm:ss");
      post.timestamp_formatted = _moment.format('DD.MM.YYYY HH:mm');

      post.post_date = moment(post.post_date || post.timestamp).format("YYYY-MM-DD HH:mm:ss");
      post.post_date_gmt = moment((post.post_date || post.post_date_gmt) || post.timestamp).format("YYYY-MM-DD HH:mm:ss");
      post.date_gmt = post.post_date;

      post.active = !!post.active;
      post.post_type = post.post_type || 'draft';
      post.user = post.user || this.userService.getUid();

      delete post.checked;
      delete post.end;
      delete post.start;

      this.AppCMS.loadPluginData('pipeline', {
        post: post,
      }, ['posts', 'create']).then(resolve).catch(reject);
    });
  }

  updatePost(post: post) {
    return this.posts.updatePost(post);
  }

}