import { Injectable } from '@angular/core';

import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from "src/app/services/core/events.service";

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  iDefaultLimit: number = 1000;

  constructor(
    private cache: CacheService,
    private events: EventsService,
  ) {

  }

  calculateBack(view: any, config: any | null = null) {
    config = config || {};
    config.offset = config.offset || 0;
    config.offset--;

    return this.calculateConfig(view, config);
  }

  async calculateConfig(view: any, config: any | null = null) {
    config = config || {};
    config.limit = config.limit || (await this.getLimit());
    config.offset = config.offset || 0;

    // calc view vars
    config.canBack = (config.offset > 0);
    config.canNext = false;

    if (!config.itemsKey) {
      return false;
    }

    // store to backup if not exists
    if (!config.hasOwnProperty('backup') && !!config.itemsKey && !!config.itemsParentKey && !!view[config.itemsParentKey] && !!view[config.itemsParentKey][config.itemsKey]) {
      config.backup = JSON.parse(JSON.stringify(view[config.itemsParentKey][config.itemsKey]));
    } else
      if (!config.hasOwnProperty('backup') && !!config.itemsKey && !!view[`${config.itemsKey}_backup`]) {
        config.backup = JSON.parse(JSON.stringify(view[`${config.itemsKey}_backup`]));
      } else
        if (!config.hasOwnProperty('backup') && !!config.itemsKey && !!view[config.itemsKey]) {
          config.backup = JSON.parse(JSON.stringify(view[config.itemsKey]));
        }

    if (!config.backup) {
      return false;
    }

    let buttons: any = [];

    const blOverLimit: boolean = ((config.count || config.backup.length) > config.limit),
      iStart: number = (config.offset * config.limit),
      iEnd: number = (iStart + config.limit);

    if (!!config.itemsKey && !!config.backup && !!config.limit && !!blOverLimit) {

      if (!!config.itemsParentKey) {
        view[config.itemsParentKey][config.itemsKey] = config.backup.slice(iStart, iEnd);
      } else {
        view[config.itemsKey] = config.backup.slice(iStart, iEnd);
      }

      // Calculate buttons
      const iMaxButtons = 6;
      const iSteps = (Math.ceil((config.count || config.backup.length) / config.limit) + config.offset);

      let iCurrent: number = 1;

      if (iSteps > 0) {

        while (iSteps > iCurrent) {
          let blInVisibleRange: boolean = true;

          if ((iSteps > iMaxButtons) || (config.offset > (iMaxButtons - 1))) {
            blInVisibleRange = (iCurrent > (config.offset - (iMaxButtons / 2)) && iCurrent < (config.offset + (iMaxButtons / 2)));
          }

          if (blInVisibleRange) {
            buttons.push({
              index: iCurrent,
              label: (iCurrent + 1),
            });
          }

          iCurrent++;
        }

        config.canNext = !!blOverLimit;
      }
    }

    this.events.publish('pagination:buttons:changed', buttons);

    return {
      buttons: buttons,
      config: config,
      view: view,
    }
  }

  calculateNext(view: any, config: any | null = null) {
    config = config || {};
    config.offset = config.offset || 0;
    config.offset++;

    return this.calculateConfig(view, config);
  }

  getDefaultLimit() {
    return this.iDefaultLimit;
  }

  async getLimit() {
    const fromCache: any = await this.cache.get('pagination_limit', -1);
    return (!!fromCache && !!fromCache.data ? parseInt(`${fromCache.data}`) : this.getDefaultLimit());
  }

  setLimit(iLimit: number) {
    return this.cache.set('pagination_limit', iLimit);
  }

}